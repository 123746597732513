<template>
  <base-material-card id="tabs">
    <v-select
      class="my-2"
      v-if="$vuetify.breakpoint.xsOnly"
      :items="tabs"
      item-text="text"
      item-value="href"
      v-model="tab"
      outlined
      dense
      style="height: 20px"
    ></v-select>
    <v-tabs
      v-if="!$vuetify.breakpoint.xsOnly"
      v-model="tab"
      right
      class="pl-0"
      style="border-color: white; border-bottom: -2px; border-width: 1px"
    >
      <v-tab
        href="#inventory"
        class="pl-0 ml-0 pr-0"
        active-class="color"
        style="font-size: 14px"
        >{{ $t("products") }}</v-tab
      >
      <v-tab href="#movements" active-class="color" style="font-size: 14px">{{
        $t("inventorys.movement")
      }}</v-tab>

      <!--<v-tab
          href="#brand"
          active-class="color"
          style="font-size: 14px"
          >{{ $t("brands") }}</v-tab
        >

        <v-tab
          href="#category"
          active-class="color"
          style="font-size: 14px"
          >{{ $t("categorys") }}</v-tab
        >-->
      <v-tab
        href="#suppliers"
        active-class="color"
        style="font-size: 14px"
        v-text="$t('inventorys.supplier')"
      />

      <v-row justify="end" align="center">
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn elevation="0" icon v-on="on" class="mt-2">
              <v-icon size="36">mdi-plus-circle</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="
                $refs.inventorys.dialog = true;
                $refs.inventorys.inventory = {};
              "
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t("new", { name: $tc("inventorys.product") }) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="$refs.brands.dialog = true">
              <v-list-item-icon class="mr-3 my-md-2 my-2">
                <v-icon small>mdi-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="ml-n3">
                <v-list-item-title>
                  {{ $t("inventorys.brands") }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="$refs.categorys.dialog = true">
              <v-list-item-icon class="mr-3 my-md-2 my-2">
                <v-icon small>mdi-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="ml-n3">
                <v-list-item-title>
                  {{ $t("inventorys.categorys") }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-row>
    </v-tabs>
    <v-tabs-items v-model="tab" style="border-color: red; border-width: medium">
      <v-tab-item value="inventory" style="width: 100%">
        <inventory ref="inventory" />
      </v-tab-item>
      <v-tab-item value="movements" style="width: 100%">
        <movement ref="movement" />
      </v-tab-item>
      <!--
          <v-tab-item value="brand">
          <brand/>
        </v-tab-item>
        <v-tab-item value="category">
          <category/>
        </v-tab-item>
        -->
      <v-tab-item value="suppliers">
        <supplier ref="suppliers"></supplier>
      </v-tab-item>
    </v-tabs-items>
  </base-material-card>
</template>

<script>
export default {
  name: "InventoryTabs",
  data() {
    return {
      tab: "movements",
    };
  },
  watch: {
    tab: {
      handler() {
        if (this.$refs.inventory) this.$refs.inventory.reload();
        if (this.$refs.movement) this.$refs.movement.reload();
      },
      deep: true,
    },
  },
  computed: {
    tabs() {
      return [
        { href: "inventory", text: this.$t("products") },
        { href: "movements", text: this.$tc("movement", 2) },
        { href: "suppliers", text: this.$t("inventorys.supplier") },
      ];
    },
  },
  mounted() {
    this.tab = this.$route.params.type ? this.$route.params.type : "inventory";
  },
  components: {
    inventory: () => import("@/views/inventory/InventoryList"),
    movement: () => import("@/views/inventory/MovementList"),
    brand: () => import("@/views/brand/BrandList"),
    category: () => import("@/views/category/CategoryList"),
    supplier: () => import("@/views/supplier/SupplierList"),
  },
};
</script>
<style lang="sass">
#tabs
  .v-tabs-slider
    position: absolute
.color
  color: var(--v-primary-base) !important

.v-text-field.v-input--dense:not(.v-textarea) fieldset
  height: 35px
</style>
